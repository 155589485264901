import styled from 'styled-components';
import './App.css';
import logo from './assets/logo.png'

function App() {
  return (
    <Wrapper>
      <Container>
        <img src={logo} style={{ width: '100%', height: '80%'}}/>
        {/* <Title>Este domínio e marca registrada estão a venda!</Title> */}
        <Contact>Contato </Contact>
        <Email>tecnologia@mobconn.com.br</Email>
      </Container>
    </Wrapper>  
    
  );
}

export default App;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 30%;
  padding-top: 5%;
  font-family: Tahoma, sans-serif;
`;

const Container = styled.div`
  height: auto;
  width: 40%;
  text-align: left;
`;

const Title = styled.div`
  font-family: 'Roboto';
  font-weight: bold;
  margin-bottom: 2rem;
  font-size: 30px;
  width: 100%;
  text-align: center;
`;

const Contact = styled.div`
  font-family: 'Roboto';
  height: 100%;
  width: 100%;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const Email = styled.div`
  font-family: 'Roboto';
  height: 100%;
  width: 100%;
  width: 100%;
  text-align: center;
`;
